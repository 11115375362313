import { ChangeDetectionStrategy, Component } from '@angular/core'
import { combineLatest, map } from 'rxjs'

import { SidebarTypesEnum } from '@alliance/clubs/utils'
import { TailwindCustomConfig } from '@alliance/santa/tailwind'
import { RxStateComponent } from '@alliance/shared/models'
import { MediaService } from '@alliance/shared/utils'
import { MobileFullScreenModalService, VerticalModalService } from '@alliance/shared/santa'
import { IconCloseCross } from '@alliance/shared/icons'

import { SidebarMainService } from '../../services/main.service'

@Component({
  selector: 'alliance-clubs-sidebar',
  templateUrl: 'main.component.html',
  styleUrls: ['./main.component.tw.css', './main-responsive.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarMainComponent extends RxStateComponent<{
  isMobileScreen: boolean
  isOuterType: boolean
}> {
  public state = this.connectViewState(['isMobileScreen', 'isOuterType'])
  public ICONS = { IconCloseCross: IconCloseCross.name }

  public constructor(
    private mediaService: MediaService,
    private mobileFullScreenModalService: MobileFullScreenModalService,
    private verticalModalService: VerticalModalService,
    private sidebarMainService: SidebarMainService
  ) {
    super()

    this.initState({
      isMobileScreen: this.mediaService.select('isMobileScreen'),
      isOuterType: combineLatest([this.mediaService.getScreen$(TailwindCustomConfig.THEME.screens['1000']), this.select('isMobileScreen'), this.sidebarMainService.select('type')]).pipe(
        map(([mediaLessThan1kScreen, isMobileScreen, sidebarType]) => !!mediaLessThan1kScreen || !!isMobileScreen || sidebarType === SidebarTypesEnum.outer)
      )
    })
  }

  public closeSidebar(): void {
    if (this.get().isMobileScreen) {
      this.mobileFullScreenModalService.close()
    } else {
      this.verticalModalService.close()
    }
  }
}
