import { ClubTag, PublicClubTopicEnameEnum } from '../gql/types-generated'

export const convertToEnum = (name: string): PublicClubTopicEnameEnum =>
  name
    .trim()
    .split('-')
    .map(word => word.toUpperCase())
    .join('_') as PublicClubTopicEnameEnum

export const isExistedTopic = (name: string): boolean => Object.values(PublicClubTopicEnameEnum).includes(convertToEnum(name))

export const isPromo = (tags: ClubTag[]): boolean => !!tags.filter(tag => tag.name === 'Промо').length
