import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: string
  Long: number
  UInt: number
  _Any: unknown
}

export interface AddClubPostInput {
  body: Scalars['String']
  title: Scalars['String']
}

export interface AddClubPostOutput {
  __typename?: 'AddClubPostOutput'
  post?: Maybe<ClubPost>
}

export interface City {
  __typename?: 'City'
  id: Scalars['ID']
}

export interface ClubMutations {
  __typename?: 'ClubMutations'
  addPost: AddClubPostOutput
  addPostToHot: ClubPost
  addPostToTop: ClubPost
  deletePostFromHot: DeletePostFromHotOutput
  deletePostFromTop: DeletePostFromTopOutput
  deleteSubscription: ClubSubscriptionOutput
  saveSubscription: ClubSubscriptionOutput
}

export interface ClubMutationsAddPostArgs {
  input: AddClubPostInput
}

export interface ClubMutationsAddPostToHotArgs {
  id: Scalars['ID']
}

export interface ClubMutationsAddPostToTopArgs {
  id: Scalars['ID']
}

export interface ClubMutationsDeletePostFromHotArgs {
  id: Scalars['ID']
}

export interface ClubMutationsDeletePostFromTopArgs {
  id: Scalars['ID']
}

export interface ClubMutationsDeleteSubscriptionArgs {
  type: ClubSubscriptionInput
}

export interface ClubMutationsSaveSubscriptionArgs {
  type: ClubSubscriptionInput
}

export interface ClubPost {
  __typename?: 'ClubPost'
  body: Scalars['String']
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  publishedAt?: Maybe<Scalars['DateTime']>
  relatedPosts?: Maybe<PublicClubPostsOutput>
  slug: Scalars['String']
  tags: Array<ClubTag>
  thumbnail: Scalars['String']
  title: Scalars['String']
  topics: Array<ClubTopic>
  userProfile?: Maybe<ClubUserProfile>
  viewsCount: Scalars['UInt']
}

export interface ClubPostRelatedPostsArgs {
  cursorPagination?: InputMaybe<PublicClubPostsCursorPaginationInput>
}

export interface ClubQueries {
  __typename?: 'ClubQueries'
  post?: Maybe<ClubPost>
  publicPosts: PublicClubPostsOutput
  tag: ClubTag
  tags: Array<ClubTag>
  topPost: ClubPost
  topic: ClubTopic
  topicEnums?: Maybe<PublicClubTopicEnameEnum>
  topics: Array<ClubTopic>
}

export interface ClubQueriesPostArgs {
  id?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
}

export interface ClubQueriesPublicPostsArgs {
  cursorPagination?: InputMaybe<PublicClubPostsCursorPaginationInput>
  filter?: InputMaybe<PublicClubPostsFilterInput>
  sorting?: InputMaybe<PublicClubPostsSortingEnum>
}

export interface ClubQueriesTagArgs {
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export interface ClubQueriesTopicArgs {
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export interface ClubSubscriptionInput {
  email: Scalars['String']
  subscriptionType: PublicClubSubscriptionTypeEnum
}

export interface ClubSubscriptionOutput {
  __typename?: 'ClubSubscriptionOutput'
  value: Scalars['String']
}

export interface ClubTag {
  __typename?: 'ClubTag'
  id: Scalars['ID']
  name: Scalars['String']
}

export interface ClubTopic {
  __typename?: 'ClubTopic'
  banner: Scalars['String']
  color: Scalars['String']
  description: Scalars['String']
  engname: Scalars['String']
  id: Scalars['ID']
  logo: Scalars['String']
  meta: Scalars['String']
  name: Scalars['String']
  sideBarIcon: Scalars['String']
}

export interface ClubUserProfile {
  __typename?: 'ClubUserProfile'
  avatarImageName: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  position: Scalars['String']
}

export interface DeletePostFromHotOutput {
  __typename?: 'DeletePostFromHotOutput'
  post?: Maybe<ClubPost>
  postId: Scalars['ID']
}

export interface DeletePostFromTopOutput {
  __typename?: 'DeletePostFromTopOutput'
  post?: Maybe<ClubPost>
  postId: Scalars['ID']
}

export interface Mutation {
  __typename?: 'Mutation'
  clubs: ClubMutations
}

export interface PublicClubPostsCursorPaginationInput {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  take?: InputMaybe<Scalars['UInt']>
}

export interface PublicClubPostsFilterInput {
  byTagName?: InputMaybe<Scalars['String']>
  byTopicName?: InputMaybe<Scalars['String']>
  isHot?: InputMaybe<Scalars['Boolean']>
  isTop?: InputMaybe<Scalars['Boolean']>
  searchKeyword?: InputMaybe<Scalars['String']>
  tagIds?: InputMaybe<Array<Scalars['ID']>>
  topicIds?: InputMaybe<Array<Scalars['ID']>>
}

export interface PublicClubPostsOutput {
  __typename?: 'PublicClubPostsOutput'
  count?: Maybe<Scalars['Long']>
  items: Array<ClubPost>
  nextCursor?: Maybe<Scalars['String']>
  previousCursor?: Maybe<Scalars['String']>
}

export enum PublicClubPostsSortingEnum {
  ByDateAsc = 'BY_DATE_ASC',
  ByDateDesc = 'BY_DATE_DESC',
  ByMonth = 'BY_MONTH',
  ByMostViewedAsc = 'BY_MOST_VIEWED_ASC',
  ByMostViewedDesc = 'BY_MOST_VIEWED_DESC',
  ByWeek = 'BY_WEEK',
  ByYear = 'BY_YEAR'
}

export enum PublicClubSubscriptionTypeEnum {
  ClubEmployer = 'CLUB_EMPLOYER',
  ClubSeeker = 'CLUB_SEEKER'
}

export enum PublicClubTopicEnameEnum {
  Career = 'CAREER',
  CareerGuide = 'CAREER_GUIDE',
  CorporateCulture = 'CORPORATE_CULTURE',
  ExpertThoughts = 'EXPERT_THOUGHTS',
  Hr = 'HR',
  News = 'NEWS',
  Recruiting = 'RECRUITING',
  ResumeJobSearch = 'RESUME_JOB_SEARCH',
  Victory = 'VICTORY'
}

export interface Query {
  __typename?: 'Query'
  _entities: Array<Maybe<_Entity>>
  _service: _Service
  clubs: ClubQueries
}

export interface Query_EntitiesArgs {
  representations: Array<Scalars['_Any']>
}

export type _Entity = City

export interface _Service {
  __typename?: '_Service'
  sdl?: Maybe<Scalars['String']>
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (parent: TParent, context: TContext, info: GraphQLResolveInfo) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddClubPostInput: AddClubPostInput
  AddClubPostOutput: ResolverTypeWrapper<AddClubPostOutput>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  City: ResolverTypeWrapper<City>
  ClubMutations: ResolverTypeWrapper<ClubMutations>
  ClubPost: ResolverTypeWrapper<ClubPost>
  ClubQueries: ResolverTypeWrapper<ClubQueries>
  ClubSubscriptionInput: ClubSubscriptionInput
  ClubSubscriptionOutput: ResolverTypeWrapper<ClubSubscriptionOutput>
  ClubTag: ResolverTypeWrapper<ClubTag>
  ClubTopic: ResolverTypeWrapper<ClubTopic>
  ClubUserProfile: ResolverTypeWrapper<ClubUserProfile>
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>
  DeletePostFromHotOutput: ResolverTypeWrapper<DeletePostFromHotOutput>
  DeletePostFromTopOutput: ResolverTypeWrapper<DeletePostFromTopOutput>
  ID: ResolverTypeWrapper<Scalars['ID']>
  Long: ResolverTypeWrapper<Scalars['Long']>
  Mutation: ResolverTypeWrapper<{}>
  PublicClubPostsCursorPaginationInput: PublicClubPostsCursorPaginationInput
  PublicClubPostsFilterInput: PublicClubPostsFilterInput
  PublicClubPostsOutput: ResolverTypeWrapper<PublicClubPostsOutput>
  PublicClubPostsSortingEnum: PublicClubPostsSortingEnum
  PublicClubSubscriptionTypeEnum: PublicClubSubscriptionTypeEnum
  PublicClubTopicEnameEnum: PublicClubTopicEnameEnum
  Query: ResolverTypeWrapper<{}>
  String: ResolverTypeWrapper<Scalars['String']>
  UInt: ResolverTypeWrapper<Scalars['UInt']>
  _Any: ResolverTypeWrapper<Scalars['_Any']>
  _Entity: ResolversTypes['City']
  _Service: ResolverTypeWrapper<_Service>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddClubPostInput: AddClubPostInput
  AddClubPostOutput: AddClubPostOutput
  Boolean: Scalars['Boolean']
  City: City
  ClubMutations: ClubMutations
  ClubPost: ClubPost
  ClubQueries: ClubQueries
  ClubSubscriptionInput: ClubSubscriptionInput
  ClubSubscriptionOutput: ClubSubscriptionOutput
  ClubTag: ClubTag
  ClubTopic: ClubTopic
  ClubUserProfile: ClubUserProfile
  DateTime: Scalars['DateTime']
  DeletePostFromHotOutput: DeletePostFromHotOutput
  DeletePostFromTopOutput: DeletePostFromTopOutput
  ID: Scalars['ID']
  Long: Scalars['Long']
  Mutation: {}
  PublicClubPostsCursorPaginationInput: PublicClubPostsCursorPaginationInput
  PublicClubPostsFilterInput: PublicClubPostsFilterInput
  PublicClubPostsOutput: PublicClubPostsOutput
  Query: {}
  String: Scalars['String']
  UInt: Scalars['UInt']
  _Any: Scalars['_Any']
  _Entity: ResolversParentTypes['City']
  _Service: _Service
}

export type AddClubPostOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddClubPostOutput'] = ResolversParentTypes['AddClubPostOutput']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CityResolvers<ContextType = any, ParentType extends ResolversParentTypes['City'] = ResolversParentTypes['City']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubMutations'] = ResolversParentTypes['ClubMutations']> = {
  addPost?: Resolver<ResolversTypes['AddClubPostOutput'], ParentType, ContextType, RequireFields<ClubMutationsAddPostArgs, 'input'>>
  addPostToHot?: Resolver<ResolversTypes['ClubPost'], ParentType, ContextType, RequireFields<ClubMutationsAddPostToHotArgs, 'id'>>
  addPostToTop?: Resolver<ResolversTypes['ClubPost'], ParentType, ContextType, RequireFields<ClubMutationsAddPostToTopArgs, 'id'>>
  deletePostFromHot?: Resolver<ResolversTypes['DeletePostFromHotOutput'], ParentType, ContextType, RequireFields<ClubMutationsDeletePostFromHotArgs, 'id'>>
  deletePostFromTop?: Resolver<ResolversTypes['DeletePostFromTopOutput'], ParentType, ContextType, RequireFields<ClubMutationsDeletePostFromTopArgs, 'id'>>
  deleteSubscription?: Resolver<ResolversTypes['ClubSubscriptionOutput'], ParentType, ContextType, RequireFields<ClubMutationsDeleteSubscriptionArgs, 'type'>>
  saveSubscription?: Resolver<ResolversTypes['ClubSubscriptionOutput'], ParentType, ContextType, RequireFields<ClubMutationsSaveSubscriptionArgs, 'type'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubPost'] = ResolversParentTypes['ClubPost']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  relatedPosts?: Resolver<Maybe<ResolversTypes['PublicClubPostsOutput']>, ParentType, ContextType, Partial<ClubPostRelatedPostsArgs>>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tags?: Resolver<Array<ResolversTypes['ClubTag']>, ParentType, ContextType>
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topics?: Resolver<Array<ResolversTypes['ClubTopic']>, ParentType, ContextType>
  userProfile?: Resolver<Maybe<ResolversTypes['ClubUserProfile']>, ParentType, ContextType>
  viewsCount?: Resolver<ResolversTypes['UInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubQueries'] = ResolversParentTypes['ClubQueries']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType, Partial<ClubQueriesPostArgs>>
  publicPosts?: Resolver<ResolversTypes['PublicClubPostsOutput'], ParentType, ContextType, Partial<ClubQueriesPublicPostsArgs>>
  tag?: Resolver<ResolversTypes['ClubTag'], ParentType, ContextType, Partial<ClubQueriesTagArgs>>
  tags?: Resolver<Array<ResolversTypes['ClubTag']>, ParentType, ContextType>
  topPost?: Resolver<ResolversTypes['ClubPost'], ParentType, ContextType>
  topic?: Resolver<ResolversTypes['ClubTopic'], ParentType, ContextType, Partial<ClubQueriesTopicArgs>>
  topicEnums?: Resolver<Maybe<ResolversTypes['PublicClubTopicEnameEnum']>, ParentType, ContextType>
  topics?: Resolver<Array<ResolversTypes['ClubTopic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubSubscriptionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubSubscriptionOutput'] = ResolversParentTypes['ClubSubscriptionOutput']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubTag'] = ResolversParentTypes['ClubTag']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubTopicResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubTopic'] = ResolversParentTypes['ClubTopic']> = {
  banner?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  engname?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logo?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sideBarIcon?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubUserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubUserProfile'] = ResolversParentTypes['ClubUserProfile']> = {
  avatarImageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export type DeletePostFromHotOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletePostFromHotOutput'] = ResolversParentTypes['DeletePostFromHotOutput']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType>
  postId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletePostFromTopOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletePostFromTopOutput'] = ResolversParentTypes['DeletePostFromTopOutput']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType>
  postId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long'
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  clubs?: Resolver<ResolversTypes['ClubMutations'], ParentType, ContextType>
}

export type PublicClubPostsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicClubPostsOutput'] = ResolversParentTypes['PublicClubPostsOutput']> = {
  count?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['ClubPost']>, ParentType, ContextType>
  nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  previousCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _entities?: Resolver<Array<Maybe<ResolversTypes['_Entity']>>, ParentType, ContextType, RequireFields<Query_EntitiesArgs, 'representations'>>
  _service?: Resolver<ResolversTypes['_Service'], ParentType, ContextType>
  clubs?: Resolver<ResolversTypes['ClubQueries'], ParentType, ContextType>
}

export interface UIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UInt'], any> {
  name: 'UInt'
}

export interface _AnyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['_Any'], any> {
  name: '_Any'
}

export type _EntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['_Entity'] = ResolversParentTypes['_Entity']> = {
  __resolveType: TypeResolveFn<'City', ParentType, ContextType>
}

export type _ServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['_Service'] = ResolversParentTypes['_Service']> = {
  sdl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  AddClubPostOutput?: AddClubPostOutputResolvers<ContextType>
  City?: CityResolvers<ContextType>
  ClubMutations?: ClubMutationsResolvers<ContextType>
  ClubPost?: ClubPostResolvers<ContextType>
  ClubQueries?: ClubQueriesResolvers<ContextType>
  ClubSubscriptionOutput?: ClubSubscriptionOutputResolvers<ContextType>
  ClubTag?: ClubTagResolvers<ContextType>
  ClubTopic?: ClubTopicResolvers<ContextType>
  ClubUserProfile?: ClubUserProfileResolvers<ContextType>
  DateTime?: GraphQLScalarType
  DeletePostFromHotOutput?: DeletePostFromHotOutputResolvers<ContextType>
  DeletePostFromTopOutput?: DeletePostFromTopOutputResolvers<ContextType>
  Long?: GraphQLScalarType
  Mutation?: MutationResolvers<ContextType>
  PublicClubPostsOutput?: PublicClubPostsOutputResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  UInt?: GraphQLScalarType
  _Any?: GraphQLScalarType
  _Entity?: _EntityResolvers<ContextType>
  _Service?: _ServiceResolvers<ContextType>
}
