import { Inject, NgModule } from '@angular/core'
import { Route, RouterModule, ROUTES } from '@angular/router'
import { WINDOW } from '@ng-web-apis/common'
import { postPageMatcher, rubricsPageMatcher, tagsPageMatcher } from '@alliance/clubs/domain'
import { log } from '@alliance/shared/logger'
import { IsNewRobotaDomainService } from '@alliance/shared/utils'
import { languageMatcher, languageMatcherDefaultUa } from '@alliance/shared/translation'
import { BudniSeoKeysEnum } from '@alliance/clubs/seo'
import { SidebarGuard } from '../guards/sidebar.guard'
import { HeaderGuard } from '../guards/header.guard'
import { PageViewAngularGuard } from '../guards/pageview-angular.guard'
import { CommonSeoGuard } from '../guards/common-seo.guard'
import { IsWebviewGuard } from '../guards/isWebview.guard'

const baseRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/shell').then(m => m.ClubsShellModule),
    data: { seoKey: BudniSeoKeysEnum.budni }
  },
  {
    matcher: postPageMatcher,
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/post-page').then(m => m.ClubsPostPageModule),
    data: { seoKey: BudniSeoKeysEnum.budniPostPage }
  },
  {
    matcher: tagsPageMatcher,
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/tags-page').then(m => m.ClubsTagsPageModule),
    data: { seoKey: BudniSeoKeysEnum.budniTagsPage }
  },
  {
    matcher: rubricsPageMatcher,
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/rubrics-page').then(m => m.ClubsRubricsPageModule),
    data: { seoKey: BudniSeoKeysEnum.budniRubricsPage }
  },
  {
    path: 'search',
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/search-page').then(m => m.ClubsSearchPageModule),
    data: { seoKey: BudniSeoKeysEnum.budni }
  },
  {
    path: 'about-budni',
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/about-us-page').then(m => m.ClubsAboutUsPageModule),
    data: { seoKey: BudniSeoKeysEnum.budni }
  },
  {
    path: 'top-posts-by-week',
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/top-posts-by-week-page').then(m => m.ClubsTopPostsByWeekPageModule),
    data: { seoKey: BudniSeoKeysEnum.budni }
  },
  {
    path: '404',
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/not-found-page').then(m => m.ClubsNotFoundPageModule)
  },
  {
    path: '**',
    loadChildren: (): Promise<unknown> => import('@alliance/clubs/not-found-page').then(m => m.ClubsNotFoundPageModule)
  }
]

const rootRoute: Route[] = [
  {
    path: '',
    canActivateChild: [SidebarGuard, HeaderGuard, PageViewAngularGuard, CommonSeoGuard, IsWebviewGuard],
    children: baseRoutes
  }
]

export const routes: Route[] = [
  {
    matcher: languageMatcher,
    children: rootRoute
  }
]

export const routesDefaultUa: Route[] = [
  {
    matcher: languageMatcherDefaultUa,
    children: rootRoute
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      relativeLinkResolution: 'legacy'
    })
  ],
  providers: [
    {
      provide: ROUTES,
      useFactory: (isNewRobotaDomainService: IsNewRobotaDomainService): Route[] => (isNewRobotaDomainService.isNewRobotaDomain() ? routesDefaultUa : routes),
      multi: true,
      deps: [IsNewRobotaDomainService]
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public constructor(@Inject(WINDOW) private readonly windowRef: Window) {
    /* FE-145
     * на cvdb при возврате с резюме на выдачу со скроллом были проблемы
     * скролл еще до того, как мы перешли на выдачу ресторился браузером
     * в итоге мы на секунду видели середину резюме или футер.
     * DEMO: https://streamable.com/z1juxb
     *
     * Первым решением было поставить `scrollRestoration: top` в роутер-модуле,
     * но в таком случае мы получали проблему с выезжающими панельками (for ex: отклики),
     * которые меняли урлу (добавляли туда id) и соотв. срабатывал скролл наверх.
     * После мы пытались поменять имплементацию `RouterScroller` на свою - https://github.com/angular/angular/blob/5332a4a9191e557d946eb6f3ccc623819462cf0f/packages/router/src/router_scroller.ts#L17
     * но там, увы, все приватное.
     *
     * В итоге пришли к такому решению - просто анфорсить `scrollRestoration` в `manual`.
     * Это решает проблему - `back` ждет возврата на выдачу и только после этого вызывается уже наш `scrollRestoration` из сервиса
     *
     * Каждый браузер выставляет свое дефолтное значение для `history.scrollRestoration` и fun-fact в том, что в `firefox` он по дифолту и так `manual`
     * но корректно начинает работать только после того, как мы засеттили вручную.
     * Magic 🧙 ‍🪄
     * */
    try {
      if (this.windowRef?.history?.scrollRestoration) {
        this.windowRef.history.scrollRestoration = 'manual'
      }
    } catch (e) {
      log.warn({ where: 'clubs', category: 'try_catch', message: 'AppRoutingModule: scrollRestoration unavailable', error: e })
    }
  }
}
