import { ChangeDetectionStrategy, Component } from '@angular/core'

import { combineLatest, map } from 'rxjs'

import { TailwindCustomConfig } from '@alliance/santa/tailwind'
import { RxStateComponent } from '@alliance/shared/models'
import { HEADER_HEIGHT, HeaderData, HeaderModeEnum, HeaderPositionModeEnum } from '@alliance/shared/header/utils'
import { MediaService } from '@alliance/shared/utils'
import { LocalStorage } from '@alliance/shared/storage'

import { IsWebviewService, SidebarTypesEnum } from '@alliance/clubs/utils'
import { SidebarMainService } from '@alliance/clubs/sidebar'
import { HeaderMainService } from '@alliance/clubs/header'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './app-responsive.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends RxStateComponent<{
  isMobileScreen: boolean
  headerData: HeaderData | null
  showSidebar: boolean
  showBannerAbout: boolean
  isWebview: boolean
}> {
  public readonly HEADER_HEIGHT = HEADER_HEIGHT
  public readonly state = this.connectViewState(['isMobileScreen', 'headerData', 'showSidebar', 'showBannerAbout', 'isWebview'])
  private readonly DEFAULT_HEADER_DATA: HeaderData = {
    headerMode: HeaderModeEnum.innerPage,
    isShowHeader: true,
    preventRenderHeaderIfMobile: false
  }

  public constructor(
    private mediaService: MediaService,
    private sidebarMainService: SidebarMainService,
    private headerMainService: HeaderMainService,
    private storageService: LocalStorage,
    private isWebviewService: IsWebviewService
  ) {
    super()

    this.initState({
      isMobileScreen: this.mediaService.select('isMobileScreen'),
      headerData: combineLatest([this.mediaService.select('isMobileScreen'), this.headerMainService.select('mode')]).pipe(
        map(([isMobileScreen, headerMode]) =>
          isMobileScreen || headerMode === HeaderPositionModeEnum.sticky
            ? {
                ...this.DEFAULT_HEADER_DATA,
                positionMode: HeaderPositionModeEnum.sticky
              }
            : this.DEFAULT_HEADER_DATA
        )
      ),
      showSidebar: combineLatest([this.mediaService.getScreen$(TailwindCustomConfig.THEME.screens['1000']), this.select('isMobileScreen'), this.sidebarMainService.select('type')]).pipe(
        map(([mediaLessThan1kScreen, isMobileScreen, sidebarType]) => !mediaLessThan1kScreen && !isMobileScreen && sidebarType === SidebarTypesEnum.inner)
      ),
      showBannerAbout: !this.storageService?.getItem('hide_budni_banner_about'),
      isWebview: this.isWebviewService.select('isWebview')
    })
  }
}
