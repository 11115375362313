import { UrlMatchResult, UrlSegment } from '@angular/router'
import { isExistedTopic } from '../helpers/helpers'

export const rubricsPageMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  const length = segments.length

  if (length === 1 && segments[0] && isExistedTopic(segments[0].toString())) {
    return { consumed: segments, posParams: { rubricSegmentsEngName: segments[0] } }
  }

  return null
}
